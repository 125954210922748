import { Col, Row, Typography } from 'antd';
import { observable, set } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type { Store } from './store';
import type { AfterMainSalesItem } from './types';

export const data = observable<AfterMainSalesItem & { isShowPhone: boolean; isShowMobile: boolean; }>({
  isShowPhone: false,
  isShowMobile: false,
});

@observer
export class ReceivePerson extends React.Component {
  render() {
    return (
      <div className={styles.receivePersonContainer}>
        <Row className={styles.itemContainer}>
          <Col
            className={`${styles.receivePersonItem}`}
            span={6}
          >
            <span className={styles.receivePersonTitle}>
              收货人
            </span>
            <section style={{ width: 'calc(100% - 100px)' }}>
              <Typography.Text
                ellipsis
                title={data.receiver}
              >
                {data.receiver}
              </Typography.Text>
            </section>
          </Col>
          <Col
            className={`${styles.receivePersonItem}`}
            span={6}
          >
            <span className={styles.receivePersonTitle}>
              手机号码
            </span>
            <section style={{ width: 'calc(100% - 100px)' }}>
              <Typography.Text
                ellipsis
                title={data.isShowPhone ? data.receiverMobile : ''}
              >
                {data.isShowPhone ? data.receiverMobile : ''}
              </Typography.Text>
              <i
                className={`${styles.icon} icon-d_inspect`}
                onClick={() => set(data, { isShowPhone: !data.isShowPhone })}
              />
            </section>
          </Col>
          <Col
            className={`${styles.receivePersonItem}`}
            span={6}
          >
            <span className={styles.receivePersonTitle}>
              固定号码
            </span>
            <section style={{ width: 'calc(100% - 100px)' }}>
              <Typography.Text
                ellipsis
                title={data.isShowMobile ? data.receiverPhone : ''}
              >
                {data.isShowMobile ? data.receiverPhone : ''}
              </Typography.Text>
              <i
                className={`${styles.icon} icon-d_inspect`}
                onClick={() => set(data, { isShowMobile: !data.isShowMobile })}
              />
            </section>
          </Col>
          <Col
            className={`${styles.receivePersonItem}`}
            span={6}
          >
            <span className={styles.receivePersonTitle}>
              邮编
            </span>
            <section style={{ width: 'calc(100% - 100px)' }}>
              <Typography.Text
                ellipsis
                title={data.postcode}
              >
                {data.postcode}
              </Typography.Text>
            </section>
          </Col>
        </Row>
        <div className={styles.itemContainer}>
          <span className={styles.receivePersonTitle}>
            省市区县
          </span>
          <Row style={{ width: 'calc(100% - 70px)' }}>
            <Col
              className={`${styles.receivePersonItem}`}
              span={24}
            >
              <section className={styles.receiverName}>
                <Typography.Text
                  ellipsis
                  title={data.address}
                >
                  {data.address}
                </Typography.Text>
              </section>
            </Col>
          </Row>
        </div>
        <div className={styles.itemContainer}>
          <span className={styles.receivePersonTitle}>
            详细地址
          </span>
          <Row style={{ width: 'calc(100% - 70px)' }}>
            <Col
              className={`${styles.receivePersonItem}`}
              span={24}
            >
              <section className={styles.receiverName}>
                <Typography.Text
                  ellipsis
                  title={data.detailAddress}
                >
                  {data.detailAddress}
                </Typography.Text>
              </section>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export function receivePersonDetail(context: Store) {
  return {
    tab: {
      name: '收货人信息',
      value: 'consignee',
    },
    isCustom: true,
    customView: ReceivePerson,
    api: {
      onQuery: ({
        pid,
        cursorRow,
        gridModel,
      }) => {
        if (cursorRow) {
          set(data, cursorRow);
          return Promise.resolve({
            status: 'Successful',
            info: '',
            data: cursorRow,
          });
        } else {
          return Promise.reject();
        }
      },
    },
  };
}
