import type { PaginationData } from 'egenie-utils';
import { ImgFormatter, request } from 'egenie-utils';
import qs from 'qs';
import React from 'react';
import type { Store } from './store';
import type { AfterDetailItem } from './types';

export function afterSalesDetailGrid(context: Store) {
  return {
    tab: {
      name: '商品明细',
      value: 'detail',
    },
    grid: {
      columns: [
        {
          key: 'status',
          name: '状态',
          width: 80,
          formatter: ({ row }) => (
            <span>
              {context.getTranslationValue('status', row.status)}
            </span>
          ),
        },
        {
          key: 'productNo',
          name: '商品编码',
          width: 200,
        },
        {
          key: 'arrivalStatus',
          name: '到货状态',
          width: 80,
          formatter: ({ row }) => (
            <span>
              {context.getTranslationValue('arrivalStatus', row.arrivalStatus)}
            </span>
          ),
        },
        {
          key: 'skuNo',
          name: 'SKU编码',
          sortable: true,
          width: 200,
        },
        {
          key: 'uniqueCode',
          name: '唯一码',
          sortable: true,
          width: 200,
        },
        {
          key: 'wmsOtherStockOrderNo',
          name: '入库单编号',
          width: 200,
          formatter: ({ row }) => (
            <a
              onClick={() => {
                if (row.wmsOtherStockOrderNo) {
                  window?.top?.egenie?.openTabId(60006, qs.stringify({ wmsOtherStockOrderNo: row.wmsOtherStockOrderNo }));
                }
              }}
            >
              {row.wmsOtherStockOrderNo}
            </a>
          ),
        },
        {
          key: 'barCode',
          name: '条形码',
          width: 200,
        },
        {
          key: 'picUrl',
          name: '图片',
          width: 60,
          formatter: ({ row }) => {
            return <ImgFormatter value={row.picUrl}/>;
          },
        },
        {
          key: 'applyBackNum',
          name: '申退数量',
          width: 80,
        },
        {
          key: 'actualBackNum',
          name: '实退数量',
          width: 80,
        },
        {
          key: 'color',
          name: '颜色',
          width: 100,
        },
        {
          key: 'size',
          name: '尺码',
          width: 100,
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'wmsAfterSaleOrderDetailId',
      sortByLocal: false,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/afterSales/index/sub',
    },
    api: {
      onQuery: ({
        data,
        pid,
        cursorRow,
        gridModel,
      }) => {
        const {
          sidx,
          sord,
          page,
          pageSize,
        } = data;
        return request<PaginationData<AfterDetailItem>>({
          url: '/api/cloud/wms/rest/after/sale/detail/list/by/page',
          method: 'POST',
          data: {
            sidx,
            sord,
            page,
            pageSize,
            wmsAfterSaleOrderId: pid,
          },
          headers: { warehouseId: cursorRow?.warehouseId || '' },
        });
      },
    },
  };
}
