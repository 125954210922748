import { Modal } from 'antd';
import type { FilterConfigData, PaginationData, ValueAndLabelData } from 'egenie-utils';
import { ExportStore, formatFilterConfigData, MainSubStructureModel, Programme, request } from 'egenie-utils';
import moment from 'moment';
import React from 'react';
import { formatWarehouseChoice, getConsignor, getOwner, getWarehouse, logSubGrid } from '../../../utils';
import { afterSalesDetailGrid } from './afterSalesDetailGrid';
import { receivePersonDetail } from './receivePerson';
import type { AfterMainSalesItem } from './types';

export class Store {
  constructor() {
    // 仓库
    getWarehouse()
      .then((info) => {
        this.programme.filterItems.updateFilterItem([
          {
            field: 'warehouseId',
            data: info,
            value: formatWarehouseChoice(info, 'warehouseId'),
          },
        ]);
        this.getDataAfterWarehouse(formatWarehouseChoice(info, 'warehouseId'));
      });

    request<FilterConfigData>({
      url: '/api/cloud/baseinfo/rest/filterSet2/getConfig2',
      params: {
        itemList: 'user',
        dictList: 'arrival_status,platform_status,after_sale_source_type,cloud_after_sale_type,cloud_after_sale_num_status',
        module: '',
      },
    })
      .then((info) => {
        const list = formatFilterConfigData(info, {
          arrival_status: 'arrivalStatus',
          platform_status: 'platformStatus',
          after_sale_source_type: 'sourceType',
          cloud_after_sale_type: 'afterSaleType',
          cloud_after_sale_num_status: 'status',
          user: 'unpackager',
        });
        this.programme.filterItems.addDict(list.reduce((prev, current) => ({
          ...prev,
          [current.field]: current.data,
        }), {}));
      });
  }

  public exportStore: ExportStore = new ExportStore({ parent: null });

  private getDataAfterWarehouse = (warehouseId: string): void => {
    getOwner({ warehouseId })
      .then((data) => this.programme.filterItems.addDict({ ownerId: data }));
  };

  public programme: Programme = new Programme({
    gridModel: new MainSubStructureModel({
      buttons: [
        {
          permissionId: '6',
          text: '导出',
          icon: 'icon-export',
          handleClick: () => {
            const ids = Array.from(this.programme.gridModel.gridModel.selectedIds).join(',');
            const fileName = '售后单';
            const exportType = 'cloud_wms_after_sale_order';
            const queryParam = this.programme.filterItems.params;
            const queryParamShow = this.programme.filterItems.translateParams.join(' ');

            if (ids) {
              this.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
            } else {
              Modal.confirm({
                title: '提示',
                content: '未选择数据将导出全部数据?',
                onOk: () => {
                  this.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
                },
              });
            }
          },
        },
      ],
      grid: {
        columns: [
          {
            key: 'sourceType',
            name: '售后来源',
            width: 150,
            formatter: ({ row }) => (
              <span>
                {this.getTranslationValue('sourceType', row.sourceType)}
              </span>
            ),
          },
          {
            key: 'wmsAfterSaleOrderNo',
            name: '售后单编号',
            width: 200,
          },
          {
            key: 'afterSaleType',
            name: '售后类型',
            width: 80,
            formatter: ({ row }) => (
              <span>
                {this.getTranslationValue('afterSaleType', row.afterSaleType)}
              </span>
            ),
          },
          {
            key: 'afterSaleRemark',
            name: '售后备注',
            width: 150,
          },
          {
            key: 'afterSaleGuide',
            name: '售后说明',
            width: 150,
          },
          {
            key: 'arrivalStatus',
            name: '到货状态',
            width: 80,
            formatter: ({ row }) => (
              <span>
                {this.getTranslationValue('arrivalStatus', row.arrivalStatus)}
              </span>
            ),
          },
          {
            key: 'oldServerRemark',
            name: '原客服备注',
            width: 150,
          },
          {
            key: 'buyerNick',
            name: '买家昵称',
            width: 150,
          },
          {
            key: 'backCourierName',
            name: '退回快递',
            width: 200,
          },
          {
            key: 'backCourierNo',
            name: '退回单号',
            width: 150,
          },
          {
            key: 'ownerId',
            name: '货主',
            width: 150,
            formatter: ({ row }) => (
              <span>
                {this.getTranslationValue('ownerId', row.ownerId)}
              </span>
            ),
          },
          {
            key: 'shopName',
            name: '店铺',
            width: 150,
          },
          {
            key: 'platformStatus',
            name: '平台状态',
            width: 150,
            formatter: ({ row }) => (
              <span>
                {this.getTranslationValue('platformStatus', row.platformStatus)}
              </span>
            ),
          },
          {
            key: 'platformCode',
            name: '原平台单号',
            width: 150,
          },
          {
            key: 'oldCourierName',
            name: '原快递公司',
            width: 150,
          },
          {
            key: 'oldCourierNo',
            name: '原快递单号',
            width: 200,
          },
          {
            key: 'applyTime',
            name: '申请时间',
            width: 150,
            sortable: true,
            formatter: ({ row }) => {
              return (
                <div>
                  {row.applyTime && moment(row.applyTime)
                    .format('YYYY-MM-DD HH:mm:ss')}
                </div>
              );
            },
          },
          {
            key: 'unpackager',
            name: '拆包人',
            width: 150,
            formatter: ({ row }) => (
              <span>
                {this.getTranslationValue('unpackager', row.unpackager)}
              </span>
            ),
          },
          {
            key: 'unpackageTime',
            name: '拆包时间',
            width: 150,
            sortable: true,
            formatter: ({ row }) => {
              return (
                <div>
                  {row.unpackageTime && moment(row.unpackageTime)
                    .format('YYYY-MM-DD HH:mm:ss')}
                </div>
              );
            },
          },
          {
            key: 'omsAfterSaleNo',
            name: '来源单号',
            width: 200,
          },
          {
            key: 'unpackageTimeOutDesc',
            name: '拆包超时',
            width: 80,
          },
        ].map((v) => ({
          resizable: true,
          sortable: false,
          ...v,
        })),
        rows: [],
        primaryKeyField: 'wmsAfterSaleOrderId',
        sortByLocal: false,
        showCheckBox: true,
        showEmpty: true,
        setColumnsDisplay: true,
        gridIdForColumnConfig: '/egenie-cloud-wms/afterSales/index/main',
      },
      pageId: '2690',

      // 主表查询api
      api: {
        onQuery: (params) => {
          const {
            filterParams,
            ...rest
          } = params;
          const _params = JSON.parse(JSON.stringify({
            ...filterParams,
            ...rest,
          }));
          return request<PaginationData<AfterMainSalesItem>>({
            url: '/api/cloud/wms/rest/after/sale/list/by/page',
            method: 'POST',
            data: _params,
            headers: { warehouseId: filterParams?.warehouseId || '' },
          });
        },
      },
      subTables: {
        activeTab: 'detail',
        tabsFlag: {
          inited: {
            detail: true,
            log: false,
          },
          searched: {},
        },
        list: [
          afterSalesDetailGrid(this),
          receivePersonDetail(this),
          logSubGrid(40000, {}, 'warehouseId'),
        ],
      },
    }),
    filterItems: [
      {
        type: 'date',
        field: 'dateType',
        label: '日期类型',
        selectValue: 'createTime',
        data: [
          {
            value: 'createTime',
            label: '申请时间',
          },
          {
            value: 'unpackageTime',
            label: '拆包时间',
          },
        ],
      },
      {
        type: 'select',
        field: 'warehouseId',
        label: '仓库',
        allowClear: false,
        onChangeCallback: (warehouseIds: string) => {
          this.getDataAfterWarehouse(warehouseIds);
          this.programme.filterItems.updateFilterItem([
            {
              field: 'ownerId',
              value: undefined,
            },
          ]);
        },
      },
      {
        type: 'input',
        label: '买家昵称',
        field: 'buyerNick',
      },
      {
        type: 'input',
        label: '手机号',
        field: 'receiverMobile',
      },
      {
        type: 'input',
        label: '退回单号',
        field: 'backCourierNo',
        isMultipleSearch: true,
      },
      {
        type: 'input',
        label: '平台单号',
        field: 'platformCode',
      },
      {
        type: 'select',
        label: '到货状态',
        field: 'arrivalStatus',
        mode: 'multiple',
      },
      {
        type: 'input',
        label: 'sku编码',
        field: 'skuNo',
      },
      {
        type: 'input',
        label: '唯一码',
        field: 'uniqueCode',
      },
      {
        type: 'select',
        label: '平台状态',
        field: 'platformStatus',
      },
      {
        mode: 'multiple',
        type: 'select',
        label: '售后来源',
        field: 'sourceType',
      },
      {
        mode: 'multiple',
        type: 'select',
        label: '货主',
        field: 'ownerId',
      },
      {
        type: 'input',
        field: 'wmsAfterSaleOrderNo',
        label: '售后单编号',
      },
      {
        type: 'input',
        field: 'oldCourierNo',
        label: '原快递单号',
        isMultipleSearch: true,
      },
      {
        type: 'input',
        field: 'omsAfterSaleNo',
        label: '来源单号',
      },
      {
        type: 'select',
        field: 'unpackageTimeOut',
        label: '拆包超时',
        data: [
          {
            value: '1',
            label: '是',
          },
          {
            value: '0',
            label: '否',
          },
        ],
      },
      {
        type: 'select',
        field: 'returnedButNotArrival',
        label: '退回未到仓',
        data: [
          {
            value: '1',
            label: '是',
          },
          {
            value: '0',
            label: '否',
          },
        ],
      },
    ],
    moduleName: 'WmsAfterSaleOrder',
  });

  public getTranslationValue = (key: string, value: string | number): string => {
    return ((this.programme.filterItems.dict[key] || []) as ValueAndLabelData).find((item) => item.value === String(value))?.label;
  };
}
