import { ExportModal, ProgrammeComponent } from 'egenie-utils';
import React from 'react';
import { Store } from './store';

export default class extends React.Component {
  public store = new Store();

  render() {
    return (
      <>
        <ProgrammeComponent store={this.store.programme}/>
        <ExportModal store={this.store.exportStore}/>
      </>
    );
  }
}
